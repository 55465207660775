import { Teaser as DefaultTeaser } from "@vgno/teasers/Teaser";

import type { Article } from "@vgno/article";

type Props = {
  article: Article["teaser"];
  index: number;
};

export const Teaser = ({ article, index }: Props) => {
  let imageAsset = article.promotionContent?.imageAsset || undefined;
  if (!imageAsset?.urls) {
    imageAsset = undefined;
  }

  const video = article.components?.find(
    (component) => component.type === "video",
  );

  let duration: number | undefined;

  if (article.contentType === "video" && video && video.type === "video") {
    duration = video.videoAsset.duration;
  }

  return (
    <track-element
      data-track-primary-position={index + 1}
      data-track-bundle-position={index + 1}
      data-track-target-newsroom={article.newsroom}
      data-track-id={`teaser:${article.id}`}
      data-track-element-size={
        article.characteristics.hotness < 70 ? "small" : "medium"
      }
      data-track-element-type="Teaser"
      data-track-click-intent="View"
      data-track-target-type="Article"
      data-track-image-url={
        article.promotionContent?.imageAsset?.urls?.[0]?.url
      }
      data-track-impression
      data-track-name={article.title.value}
      key={article.id}
    >
      <DefaultTeaser
        alt={article.promotionContent?.alternateText?.value}
        authors={article.authors}
        contentType={article.contentType}
        duration={duration}
        fetchpriority={index === 0 ? "high" : "low"}
        headlineType="h2"
        imageAsset={imageAsset}
        loading={index && index >= 1 ? "lazy" : "eager"}
        paywall={article.restrictions === "subscription"}
        size={article.characteristics.hotness < 70 ? "small" : "medium"}
        timestamp={article.changes.published}
        title={article.title.value}
        type={article.customProperties.presentation}
        url={article.links.canonicalUrl}
      />
    </track-element>
  );
};
